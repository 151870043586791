// React and related imports
import React from "react";
import { useState, useMemo, useEffect } from "react";
// Style import
import "../Common/ReactDataTable.scss";
// Custom components
import DataTable from "../Common/components/DataTable/DataTable";
import BaseCell from "../Common/components/BaseCell";
import SkeletonDatable from "../SkeletonComponents/SkeletonDatable";
import SkeletonEmptyDialogue from "../SkeletonComponents/SkeletonEmptyDialogue";
import BalanceCell from "../../utilities/BalanceCell";
// Third-party libraries
import { createColumnHelper } from "@tanstack/react-table";
import posthog from "posthog-js";
// Context provider
import { AccountBalanceContextProvider } from "./AccountBalanceContext";
// Utility functions
import { capturePosthogEvent } from "../../utilities/posthogUtils";

const AccountBalanceTable = ({
  columnList,
  isModalPresent,
  modalComponent,
  tableTitle,
  provider,
}) => {
  // Posthog event capture on page visit
  useEffect(() => {
    if (
      process.env.REACT_APP_ENV_NAME === "staging" ||
      process.env.REACT_APP_ENV_NAME === "production"
    ) {
      posthog.capture("Account Balances View", {
        path: window.location.origin + window.location.pathname,
        customer_name: JSON.parse(localStorage.getItem("user")).client_id,
        user_email: JSON.parse(localStorage.getItem("user")).email,
      });
    }
  }, []);
  function refreshTable() {
    setNoDataFound(false);
    setIsRefreshing(true);
    setFilterList({ ...filterList, currentPage: 1 });
    fetchData(1, { ...filterList, currentPage: 1 }, undefined, false);
  }
  let {
    setDateFilters,
    setFilterList,
    setInitialLoading,
    setTableData,
    fetchData,
    setNoDataFound,
    isLazyFetching,
    isLoading,
    tableData,
    filterList,
    totalCount,
    noDataFound,
    initialLoading,
    selectedTransaction,
    dateFilters,
    setLastApiTime,
    lastApiTime,
    lastInterval,
    settotalcountNotset,
    setTotalCount,
    refreshing,
    setIsRefreshing,
    isError,
    errorResponse,
    firstApiCall,
  } = React.useContext(AccountBalanceContextProvider);

  const [sortState, setSortState] = useState({
    sortBy: null,
    sortOrder: null, // true == asc, false === desc, undefined == nosort
  });
  function getSortResult(a, b, key) {
    return a[key].toString().localeCompare(b[key], undefined, {
      numeric: true,
      sensitivity: "base",
    });
  }
  function headerFn(key) {
    setSortState(({ sortBy, sortOrder }) => {
      if (sortBy === key) {
        return {
          sortBy,
          sortOrder:
            sortOrder === null ? false : sortOrder === false ? true : null,
        };
      } else {
        return {
          sortBy: key,
          sortOrder: false,
        };
      }
    });
  }

  const [selectedAccount, setSelectedAccount] = useState(null);
  const [showPopup, setShowPopup] = useState(false);
  const [isFiltered, setFilter] = useState(false);
  const transactionColumnList = [
    {
      accessor: "Account_Type",
      name: "Account Alias",
      showInitial: true,
    },
    {
      accessor: "Debit_VA_Balance",
      name: "Debit VA Balance (in Rs)",
      showInitial: true,
      cell: BalanceCell

    },
    {
      accessor: "Master_VA_balance",
      name: "Master VA Balance (in Rs)",
      showInitial: true,
      cell: BalanceCell

    },
  ];
  const columnHelper = createColumnHelper();
  const [toggleEntries, setToggleEntries] = useState(false);
  const [toggleColumn, setToggleColumn] = useState(false);
  const [showTransactions, setShowTransactions] = useState(false);
  const [showModal, setShowModal] = useState(false);

  const showCsvModal = () => {
    setShowModal(true);
  };
  function showConsumerId(id, original) {
    setShowPopup(true);
    setSelectedAccount(original);
  }
  const hideCsvModal = () => {
    setShowModal(false);
  };

  const [columnsToShow, setColumnsToShow] = useState(
    transactionColumnList.filter((item) => item.showInitial).map((x) => x.name)
  );
  const columns = useMemo(() => {
    const arr = transactionColumnList;
    return arr
      .filter((x) => columnsToShow.includes(x.name))
      .map((x) =>
        columnHelper.accessor(x?.accessor ?? x.name, {
          cell: x?.cell ?? BaseCell,
          header: x?.header ?? x.name,
          enableSorting: x.enableSorting ?? true,
          clickFn: x.clickFn ?? null,
          filterFn: x.filterFn,
          hasPopup: x.hasPopup,
          headerFn: x.enableSorting !== false ? headerFn : undefined,
          sortState:
            sortState.sortBy === x.accessor
              ? sortState.sortOrder === false
                ? "desc"
                : sortState.sortOrder === true
                  ? "asc"
                  : undefined
              : undefined,
          ...x,
        })
      );
  }, [columnsToShow, sortState]);
  const initializeCsv = () => {
    const csvHeaders = columnsToShow.join(",");
    const columnData = transactionColumnList;
    const dataTypes = columnData.filter((x) => columnsToShow.includes(x.name));

    const rows = tableData.map((x) => {
      return dataTypes.map((y) => x[y.accessor]).join(",");
    });
    const csvData =
      "data:text/csv;charset=utf-8," + [csvHeaders, ...rows].join("\n");
    const encodedUri = encodeURI(csvData);
    const link = document.createElement("a");
    link.setAttribute("href", encodedUri);
    link.setAttribute("download", "table-data.csv");
    document.body.appendChild(link);
    link.click();
    hideCsvModal();
  };
  const [filteredOptions, setFilteredOptions] = useState({});

  const { dataToShow, maxPages, startIndex, endIndex, possibleOptions } =
    useMemo(() => {
      const swapValue = (obj) => {
        Object.keys(obj).forEach((key) => {
          if (!obj[key]) {
            obj[key] = "-";
          }
        });
        return obj;
      };
      let data = tableData.map((d) => swapValue(d));

      if (filterList.search !== "") {
        data = data.filter((x) => {
          const string = filterList.search.toLowerCase();
          return JSON.stringify(x).toLowerCase().includes(string);
        });
      }
      let obj = {};
      data.forEach((item) => {
        for (const [itemKey, itemValue] of Object.entries(item)) {
          if (obj[itemKey] !== undefined) {
            obj[itemKey].push(itemValue);
          } else {
            obj[itemKey] = [itemValue];
          }
        }
      });
      Object.keys(obj).forEach((item) => {
        obj[item] = obj[item].filter((a, i) => a && obj[item].indexOf(a) === i);
        if (obj[item]?.length > 30) {
          obj[item] = [];
        }
      });
      const possibleOptions = obj;

      Object.keys(filteredOptions).forEach((item) => {
        if (filteredOptions[item]) {
          data = data.filter(
            (fullItem) => fullItem[item] === filteredOptions[item]
          );
        }
      });
      if (sortState.sortBy !== null && sortState.sortOrder !== null) {
        data = data.sort((a, b) => {
          const key = sortState.sortBy;
          if (sortState.sortOrder === false) {
            return getSortResult(a, b, key) * -1;
          }
          if (sortState.sortOrder === true) {
            return getSortResult(a, b, key);
          }
        });
      }
      const maxPages = Math.ceil(data.length / filterList.resultsPerPage);
      const startIndex =
        (filterList.currentPage - 1) * filterList.resultsPerPage;
      const endIndex = startIndex + filterList.resultsPerPage;

      const paginatedData = data?.filter(
        (x, i) => i >= startIndex && i < endIndex
      );

      return {
        dataToShow: paginatedData,
        maxPages,
        startIndex,
        endIndex,
        possibleOptions,
      };
    }, [
      tableData,
      filterList,
      noDataFound,
      isFiltered,
      sortState,
      filteredOptions,
    ]);
  const allColumns = useMemo(() => transactionColumnList, [showTransactions]);
  const toggleEntrie = (e) => {
    if (typeof e === "boolean") {
      setToggleEntries(e);
      return;
    }
    setToggleEntries(!toggleEntries);
  };
  const toggleColum = (e) => {
    if (typeof e === "boolean") {
      setToggleColumn(e);
      return;
    }
    setToggleColumn(!toggleColumn);
  };
  const handleColumnCheck = (name) => {
    if (columnsToShow.includes(name)) {
      const index = columnsToShow.findIndex((x) => x === name);
      setColumnsToShow((prev) => {
        const items = [...prev];
        items.splice(index, 1);
        return [...items];
      });
    } else {
      setColumnsToShow([...columnsToShow, name]);
    }
  };

  function Timer() {
    useEffect(() => { }, [lastApiTime]);
    return (
      <>
        <div className="timer" style={{ fontSize: 11 }}>
          Updated : {lastApiTime} Min ago
        </div>
      </>
    );
  }

  const toggleTransactions = (e) => {
    setFilterList({
      ...filterList,
      currentPage: 1,
      search: "",
    });
    setFilter(false);
    setSortState({
      sortBy: null,
      sortOrder: null, // true == asc, false === desc, undefined == nosort
    });
    setTableData([]);
    setShowTransactions(e);
    setNoDataFound(false);
    if (e) {
      setColumnsToShow(transactionColumnList.map((x) => x.name));
      return;
    }
    setInitialLoading(true);
    setColumnsToShow(transactionColumnList.map((x) => x.name));
  };
  const handleFilter = (key, val) => {
    setFilteredOptions((old) => {
      let newVal = { ...old };
      newVal[key] = val;
      return newVal;
    });
  };
  useEffect(() => {
    if (tableData.length >= totalCount && tableData.length !== 0) return;
    const prefetchLimit = 200;
    const endIndex = filterList.currentPage * filterList.resultsPerPage;
    const limitConstant =
      Math.ceil((endIndex + 1) / prefetchLimit) * prefetchLimit +
      2 * filterList?.resultsPerPage;
    if (totalCount > 0) {
      if (tableData.length < limitConstant && firstApiCall === false) {
        fetchData(tableData.length + 1, undefined, undefined, undefined, false);
      }
    } else if (
      totalCount === 0 &&
      tableData.length === 0 &&
      firstApiCall === true
    ) {
      fetchData(tableData.length + 1, undefined, undefined, undefined, false);
    }
  }, [filterList.currentPage]);
  return (
    <>
      {!isError ? (
        <div className="ButtonWrapper">
          <div
            style={{
              overflowX: "scroll",
              display: "flex",
              flexDirection: "column",
            }}
          >
            {/* {isLoading && initialLoading ? (
              <SkeletonDatable />
            ) : ( */}
            <>
              <div className="total-count">
                {!showTransactions && (
                  <div className="title">Account Balances</div>
                )}

                <div
                  className={`virtual-accounts-btn ${refreshing && "refreshing-btn"
                    }`}
                  style={
                    !refreshing
                      ? { background: "#e5f4ff", color: "#0092ff" }
                      : { backgroundColor: "#f6fbfd", color: "#c4e1ff" }
                  }
                  onClick={() => {
                    if (
                      process.env.REACT_APP_ENV_NAME === "staging" ||
                      process.env.REACT_APP_ENV_NAME === "production"
                    ) {
                      capturePosthogEvent(
                        window.location.origin,
                        window.location.pathname,
                        "refresh"
                      );
                    }
                    refreshTable();
                  }}
                >
                  {refreshing || isLoading ? (
                    <>
                      <span>
                        <img
                          src="/images/Refresh-green.svg"
                          alt="refresh icon"
                        />
                      </span>
                      Fetching Balances..
                    </>
                  ) : (
                    <>
                      <span>
                        <img
                          src="/images/refresh-icon.svg"
                          alt="refresh icon"
                        />
                      </span>
                      Refresh Balances
                    </>
                  )}
                </div>
              </div>
              <DataTable
                optionList={possibleOptions}
                filteredOptions={filteredOptions}
                columns={columns}
                setFilter={handleFilter}
                isFiltered={isFiltered}
                data={isLoading ? null : dataToShow}
                enablePagination
                isLoading={isLoading}
                refreshing={refreshing}
                pageIndex={filterList?.currentPage}
                totalMaxPages={Math.ceil(10 / filterList?.resultsPerPage)}
                maxPages={maxPages}
                setPageIndex={(e) => {
                  setFilterList({
                    ...filterList,
                    currentPage: e,
                  });
                }}
              />
            </>
          </div>
        </div>
      ) : (
        <SkeletonEmptyDialogue type={errorResponse} />
      )}
    </>
  );
};

export default AccountBalanceTable;

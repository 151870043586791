// React and related imports
import React, { useContext, useEffect, useState } from "react";
import ReactDOM from "react-dom";
// Third-party libraries
import axios from "axios";
import posthog from "posthog-js";
// API and configuration imports
import { APIConfig } from "../../services/apiConfiguration";
import apiEndpointList from "../../config/core_banking/endpoint";
// Custom components
import TimeCell from "../../utilities/TimeCell";
import ReactDataTable from "../Common/ReactDataTable";
import ShowPayoutsModalCell from "./ShowPayoutsModalCell";
import StatusCell from "../../utilities/StatusCell/StatusCell";
import SkeletonEmptyDialogue from "../SkeletonComponents/SkeletonEmptyDialogue";
import InitiatePayoutModal from "./InitiatePayoutForm/InitiatePayoutModal";
import SnackbarMessage from "../Shared/SnackbarMessage/SnackbarMessage";
import BalanceCell from "../../utilities/BalanceCell";
// Context provider
import { PayoutsContextProvider } from "./PayoutsContext";
// Utility functions
import { capturePosthogEvent } from "../../utilities/posthogUtils";
// Style import
import "./Payouts.scss";

const CancelToken = axios.CancelToken;
const source = CancelToken.source();

const InitiatePayout = ({ openUpiLink, cta2faEnabled }) => {
  const { isLoading } = useContext(PayoutsContextProvider);

  return (
    <div className="payout-action-container">
      {!cta2faEnabled ? (
        <p className="action-alert-text">Activate 2FA to initiate payout</p>
      ) : (
        ""
      )}
      <button
        className={
          isLoading
            ? "btn-loading"
            : `payouts-action-btn ${!cta2faEnabled ? "disabled" : ""}`
        }
        style={{ backgroundColor: "#0092ff" }}
        disabled={!cta2faEnabled}
        onClick={() => {
          if (!isLoading) {
            if (
              process.env.REACT_APP_ENV_NAME === "staging" ||
              process.env.REACT_APP_ENV_NAME === "production"
            ) {
              capturePosthogEvent(
                window.location.origin,
                window.location.pathname,
                "cta"
              );
            }
            openUpiLink();
          }
        }}
      >
        {!cta2faEnabled ? (
          <img
            style={{
              width: "16px",
              fontSize: "8px",
            }}
            className="payouts-action-icon"
            src="/images/cta-info.svg"
            alt="info icon"
          />
        ) : (
          ""
        )}
        Initiate Payout
      </button>
    </div>
  );
};

const PayoutsReactTable = () => {
  // * CTA payouts state
  const [ctaEnabled, setCtaEnabled] = useState(false);
  const [cta2faEnabled, setCta2faEnabled] = useState(false);

  // * Posthog event capture on page visit
  useEffect(() => {
    if (
      process.env.REACT_APP_ENV_NAME === "staging" ||
      process.env.REACT_APP_ENV_NAME === "production"
    ) {
      posthog.capture("Payouts View", {
        path: window.location.origin + window.location.pathname,
        customer_name: JSON.parse(localStorage.getItem("user")).client_id,
        user_email: JSON.parse(localStorage.getItem("user")).email,
      });
    }
  }, []);

  // * Allow payouts API call
  const allowInitiatePayoutApi = () => {
    APIConfig.API_Client.post(
      apiEndpointList.ALLOW_PAYMENTS_PAYOUTS.baseUrl +
      apiEndpointList.ALLOW_PAYMENTS_PAYOUTS.endpoint,
      { client_id: JSON.parse(localStorage.getItem("user")).client_id },
      { cancelToken: source.token }
    )
      .then((response) => {
        setCtaEnabled(response.data[0].enabled);

        if (response.data[0].enabled) {
          // MFA status API call
          mfaToggleStatus();
        }
      })
      .catch((error) => {
        console.error("Error initiating payout:", error);
      });
  };

  //*  MFA toggle status api
  const mfaToggleStatus = () => {
    APIConfig.API_Client.get(
      apiEndpointList.MFA_TOGGLE_DETAIL.baseUrl +
      apiEndpointList.MFA_TOGGLE_DETAIL.endpoint
    )
      .then((response) => {
        setCta2faEnabled(response?.data?.sms_mfa_enabled);
      })
      .catch((error) => { });
  };

  // * Allow payouts useEffect
  useEffect(() => {
    if (process.env.REACT_APP_ENV_NAME === "production") {
      allowInitiatePayoutApi();
    }
    if (process.env.REACT_APP_ENV_NAME === "staging" || "qa") {
      setCtaEnabled(true);
      setCta2faEnabled(true);
    }
  }, []);

  const {
    initialLoading,
    isError,
    errorResponse,
    refreshTable,
    setShowModalDialog,
    showModalDialog,
    tableData,
    totalCount,
    filterList,
    firstApiCall,
    fetchData,
    isLoading,
    setTableData,
  } = useContext(PayoutsContextProvider);

  useEffect(() => {
    if (tableData.length >= totalCount && tableData.length !== 0) return;
    const prefetchLimit = 200;
    const endIndex = filterList.currentPage * filterList.resultsPerPage;
    const limitConstant =
      Math.ceil((endIndex + 1) / prefetchLimit) * prefetchLimit +
      2 * filterList?.resultsPerPage;
    if (totalCount > 0) {
      if (tableData.length < limitConstant && firstApiCall === false) {
        fetchData(tableData.length + 1, undefined, undefined, undefined, false);
      }
    } else if (
      totalCount === 0 &&
      tableData.length === 0 &&
      firstApiCall === true
    ) {
      fetchData(tableData.length + 1, undefined, undefined, undefined, false);
    }
  }, [filterList.currentPage]);

  function openUpiLink() {
    setShowModalDialog(true);
  }

  const getTxnStatus = (row, code) => {
    APIConfig.API_Client.defaults.headers.get["provider_code"] = code;

    const cell = document.getElementById(
      `status-cell-${row.original.decentro_urn}`
    );
    if (!cell) return;

    APIConfig.API_Client.get(
      apiEndpointList.PAYOUTS_STATUS.baseUrl +
      apiEndpointList.PAYOUTS_STATUS.endpoint +
      `?decentro_txn_id=${row.original.decentro_urn}`,

      { cancelToken: source.token }
    )
      .then((response) => {
        if (response.data.status === "success") {
          const successElement = <div className="success">SUCCESS</div>;
          cell.innerHTML = "";
          ReactDOM.render(successElement, cell);
          ReactDOM.render(
            <SnackbarMessage
              msgtype="success"
              msg={"Status updated to Success"}
            />,
            document.getElementById("snackbar")
          );
          return;
        } else if (response.data.status === "failure") {
          const failureElement = <div className="failure">FAILURE</div>;
          cell.innerHTML = "";
          ReactDOM.render(failureElement, cell);
          ReactDOM.render(
            <SnackbarMessage
              msgtype="error"
              msg={"Status updated to Failure"}
            />,
            document.getElementById("snackbar")
          );
          return;
        }
        ReactDOM.render(
          <SnackbarMessage msgtype="warning" msg={"Status not updated"} />,
          document.getElementById("snackbar")
        );
      })
      .catch((error) => {
        ReactDOM.render(
          <SnackbarMessage
            msgtype="error"
            msg={`${error?.response?.data?.message} | Status: ${error?.response?.data?.status}`}
          />,
          document.getElementById("snackbar")
        );
      });
  };

  const transactionColumnList = [
    {
      accessor: "decentro_urn",
      name: "Decentro URN",
      showInitial: true,
      cell: ShowPayoutsModalCell,
    },
    {
      accessor: "customer_reference_number",

      name: "Customer",
      showInitial: true,
    },
    {
      accessor: "timestamp",

      name: "Date-Time",
      cell: TimeCell,
      showInitial: true,
    },
    {
      accessor: "transfer_type",
      showInitial: true,
      name: "Transfer Type",
      hasPopup: true,
    },
    {
      accessor: "bank_reference_number",
      name: "Bank Ref",
      showInitial: true,
    },

    {
      accessor: "transaction_status",
      name: "status",
      showInitial: true,
      hasPopup: true,
      // cell: StatusCell,
      cell: (row) => (
        <div
          className="status-update"
          id={`status-cell-${row.row.original.decentro_urn}`}
        >
          <StatusCell {...row} />
          {row.getValue() === "PENDING" && (
            <div
              className="refresh-icon-wrapper"
              onClick={() =>
                getTxnStatus(row.row, row.row.original.provider_code)
              }
            >
              <img
                src="/images/refresh-icon.svg"
                alt="Get Txn Status"
                title="Get Txn Status"
              />
            </div>
          )}
        </div>
      ),
    },
    {
      accessor: "provider",
      name: "provider",
      showInitial: true,
      hasPopup: true,
    },
    {
      accessor: "provider_code",
      name: "provider code",
      showInitial: false,
      hasPopup: false,
      cell: (row) => <div>{row.getValue()}</div>,
    },
    { accessor: "amount", name: "amount", showInitial: true, cell: BalanceCell },
    {
      accessor: "beneficiary_name",
      name: "Beneficiary Name",
      showInitial: true,
    },
    {
      accessor: "payer_account_number",
      showInitial: true,
      name: "Payer Account",
    },
    {
      accessor: "payee_account_number",
      showInitial: true,
      name: "Payee Account",
    },
    {
      accessor: "payee_ifsc_code",
      showInitial: true,
      name: "Payee IFSC",
    },
    {
      accessor: "payee_vpa",
      showInitial: true,
      name: "Payee VPA",
    },
    {
      accessor: "message",
      showInitial: true,
      name: "Purpose Message",
    },
    {
      accessor: "provider_message",
      showInitial: true,
      name: "Provider Message",
    },
  ];

  return (
    <>
      {!isError ? (
        <ReactDataTable
          tableTitle={"Payouts"}
          provider={PayoutsContextProvider}
          isModalPresent={ctaEnabled}
          modalComponent={
            <InitiatePayout
              openUpiLink={openUpiLink}
              cta2faEnabled={cta2faEnabled}
            />
          }
          columnList={transactionColumnList}
          ModalDialog={<InitiatePayoutModal goBackToTable={refreshTable} />}
          showDownloadBtn={false}
        />
      ) : (
        <SkeletonEmptyDialogue type={errorResponse} />
      )}
    </>
  );
};

export default PayoutsReactTable;
